<template>
  <div class="contents register">
    <div class="title flexB">
      <h2>메타데이터 상세보기</h2>
      <p>{{ name }} ({{ team }})</p>
    </div>
    <div class="box one">
      <div class="registerBox">
        <div class="left">
          <div class="flexL">
            <p>폴더명</p>
            <input type="text" v-model="folderName" />
            <!-- <span v-if="editMode">{{ folderName }}</span> -->
          </div>
          <div class="flexL">
            <p>언어</p>
            <select v-model="language">
              <option value disabled>선택</option>
              <option value="ko">ko</option>
              <option value="en">en</option>
            </select>
          </div>
          <div class="flexL">
            <p>제작유형</p>
            <select
              v-model="productionType"
              @change="handleFirstDropdownChanged"
            >
              <option value disabled>선택</option>
              <option
                v-for="option in firstOptions"
                :value="option"
                :key="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="flexL">
            <p>IP 유형</p>
            <select v-model="ipType">
              <option value disabled>선택</option>
              <option value="po">po</option>
              <option value="ta">ta</option>
              <option value="ti">ti</option>
              <option value="pota">pota</option>
              <option value="poti">poti</option>
              <option value="zl">zl</option>
            </select>
          </div>
          <div class="flexL">
            <p>콘텐츠유형</p>
            <select v-model="contentType">
              <option value disabled>선택</option>
              <option
                v-for="option in secondOptions"
                :value="option"
                :key="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <div class="flexL">
            <p>대표이미지</p>
            <div class="thumbnail-field">
              <el-upload
                class="upload-thumbnail"
                :on-remove="() => mainImage = ''"
                :on-change="handleThumbnailFileChange"
                drag
                action="#"
                :show-file-list="false"
                :auto-upload="false"
                :before-upload="beforeThumbnailUpload"
              >
                <img v-if="mainImage" :src="mainImage" class="thumbnail">
                <div class="info-msg" v-bind:style="{ 'display': mainImage ? null : 'flex' }">
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">파일을 끌어다 놓거나 클릭해서 업로드</div>
                </div>
              </el-upload>
              <el-link type="primary" v-show="mainImage" v-bind:href="mainImage" target="_blank">
                {{decodeURI(mainImage.split('/').slice(-2).join('/'))}}
              </el-link>
              <el-button v-show="mainImage" type="text" icon="el-icon-delete" @click="mainImage = ''"></el-button>
            </div>
          </div>
          <div class="flexL">
            <p>서버 디렉토리 경로</p>
            <input type="text" v-model="serverPath" />
          </div>
          <div class="flexL">
            <p>영상 구분</p>
            <select v-model="videoType">
              <option value disabled>선택</option>
              <option value="2D">2D</option>
              <option value="3D">3D</option>
              <option value="Real">Real</option>
            </select>
          </div>
          <div class="flexL">
            <p>오디오 언어</p>
            <el-select v-model="audioLanguages" multiple placeholder="선택" options>
              <el-option
                v-for="o in audioLanguageOptions"
                :key="o"
                :label="o"
                :value="o"
              ></el-option>
            </el-select>
          </div>
          <div class="flexL">
            <p>상영시간</p>
            <input type="text" v-model="duration" placeholder="예) 01:00:00" />
          </div>
          <div class="flexL">
            <p>시리즈제목</p>
            <input type="text" v-model="seriesTitleKorean" />
          </div>
          <div class="flexL">
            <p>Series Title</p>
            <input type="text" v-model="seriesTitleEnglish" />
          </div>
          <div class="flexL">
            <p>시리즈 시놉시스</p>
            <textarea
              v-model="seriesSynopsisKorean"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL">
            <p>Series Synopsis</p>
            <textarea
              v-model="seriesSynopsisEnglish"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL">
            <p>에피소드 회차</p>
            <input type="text" v-model="episodeNumber" />
          </div>
          <div class="flexL">
            <p>에피소드명(국문)</p>
            <input type="text" v-model="episodeTitleKorean" />
          </div>
          <div class="flexL">
            <p>에피소드명(영문)</p>
            <input type="text" v-model="episodeTitleEnglish" />
          </div>
        </div>
        <div class="right">
          <div class="flexL textBox">
            <p>장문 줄거리 <br />{{ byteCount }}/{{ byteLimit }} bytes</p>
            <textarea
              @input="checkByteLimit('long')"
              v-model="longSynopsis"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL textBox">
            <p>단문 줄거리 <br />{{ byteCount2 }}/{{ byteLimit2 }} bytes</p>
            <textarea
              @input="checkByteLimit('short')"
              v-model="shortSynopsis"
              class="small"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <div class="flexL">
            <p>작사가</p>
            <input type="text" v-model="lyricist" />
          </div>
          <div class="flexL">
            <p>작곡자</p>
            <input type="text" v-model="composer" />
          </div>
          <div class="flexL">
            <p>편곡자</p>
            <input type="text" v-model="arranger" />
          </div>
          <div class="flexL">
            <p>가수</p>
            <input type="text" v-model="singer" />
          </div>
          <div class="flexL">
            <p>곡 출처</p>
            <input type="text" v-model="songSource" />
          </div>
          <div class="flexL">
            <p>주연</p>
            <input
              type="text"
              v-model="mainCast"
              placeholder=",로 구분해서 적어주세요. 예) 뽀로로,크롱,루피"
            />
          </div>
          <div class="flexL">
            <p>등장인물</p>
            <input
              type="text"
              v-model="characters"
              placeholder=",로 구분해서 적어주세요. 예) 뽀로로,크롱,루피"
            />
          </div>
          <div class="flexL">
            <p>최초출시연도</p>
            <input
              type="text"
              v-model="initialReleaseYear"
              placeholder="예) 2023"
            />
          </div>
          <div class="flexL">
            <p>출시날짜</p>
            <input type="text" v-model="releaseDate" placeholder="예) 01.01" />
          </div>
          <div class="flexL">
            <p>장르</p>
            <input type="text" v-model="genre" />
          </div>
          <div class="flexL">
            <p>감독</p>
            <input type="text" v-model="director" />
          </div>
          <div class="flexL">
            <p>작가</p>
            <input type="text" v-model="writer" />
          </div>
          <div class="flexL">
            <p>성우</p>
            <input type="text" v-model="voiceActor" />
          </div>
          <div class="flexL">
            <p>제작사</p>
            <input type="text" v-model="productionCompany" />
          </div>
          <div class="flexL">
            <p>화질(only HD)</p>
            <input type="text" v-model="videoQuality" />
          </div>
          <div class="flexL">
            <p>화면비율</p>
            <input type="text" v-model="aspectRatio" />
          </div>
        </div>

        <!-- <div class="flexL">
          <p>요청상태</p>
          <p
            :class="{
              reject: status == 'REJECT',
              confirm: status == 'CONFIRM',
            }"
          >
            {{
              status == "REJECT"
                ? "반려"
                : status == "REQUEST"
                ? "승인대기"
                : status == "CONFIRM"
                ? "등록대기"
                : ""
            }}

            <span class="confirm">{{
              status == "CONFIRM"
                ? " (아래 정보 입력을 완료해야 최종적으로 등록처리 됩니다.)"
                : ""
            }}</span>
          </p>
        </div> -->
      </div>
      <div class="buttonWrap mt50">
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode && isAdmin" class="deleteBtn" @click="deleteData">
          삭제
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  createMetadata,
  fetchMetadataDetail,
  updateMetadata,
  deleteMetadata,
  uploadImage,
} from "@/api/index";
import { mapState } from "vuex";
export default {
  name: "dataRegister",

  data() {
    return {
      editMode: false,
      moment: moment,
      folderName: "",
      language: "",
      productionType: "",
      ipType: "",
      contentType: "",
      dropdownItems: {
        series: ["series"],
        newmedia: ["story", "song_dance", "curation", "shorts", "sound", "etc"],
      },
      secondOptions: [],
      mainImage: "",
      serverPath: "",
      videoType: "",
      audioLanguage: "",
      duration: "",
      seriesTitleKorean: "",
      seriesTitleEnglish: "",
      seriesSynopsisKorean: "",
      seriesSynopsisEnglish: "",
      episodeNumber: "",
      episodeTitleKorean: "",
      episodeTitleEnglish: "",
      longSynopsis: "",
      byteCount: 0,
      byteCount2: 0,
      byteLimit: 1800,
      byteLimit2: 160,
      shortSynopsis: "",
      lyricist: "",
      composer: "",
      arranger: "",
      singer: "",
      songSource: "",
      mainCast: "",
      characters: "",
      initialReleaseYear: "",
      releaseDate: "",
      genre: "",
      director: "",
      writer: "",
      voiceActor: "",
      productionCompany: "",
      videoQuality: "",
      aspectRatio: "",
      createdAt: "",
      isVisible: false,
      registerVisible: false,
      confirmStatus: false,
      metadataInfo: {},
      workDetails: "",
      audioLanguages: [],
      audioLanguageOptions: [
        '한국어', '광동어(홍콩)', '독일어', '러시아어', '루마니아어', '만다린(중국,대만)', '말레이어', '몽골어', '미얀마어', '베트남어', '벵골어(인도)', '스페인어(라틴)', '스페인어(스페인)', '아랍어', '영어', '우즈베크어', '웨일스어', '이탈리아어', '인도네시아어', '일본어','카자흐스탄어', '크메르어(캄보디아)', '키르기스어', '타갈로그(필리핀)', '타밀어(인도)', '태국어', '터키어', '페르시아어(이란)', '포르투갈어(브라질)', '포르투갈어(포르투갈)', '프랑스어', '헝가리어', '히브리어(이스라엘)', '힌디어(인도)',
      ],
    };
  },
  computed: {
    ...mapState(["name", "team", "_id", "isAdmin"]),
    firstOptions() {
      return Object.keys(this.dropdownItems);
    },
  },
  activated() {
    this.$store.dispatch("SET_NAVBAR", "0");
    if (this.$route.query.id) {
      this.editMode = true;
      this.metadataId = this.$route.query.id;
      this.getMetadata();
    }
  },
  methods: {
    handleThumbnailFileChange(file, fileList) {
      if (this.beforeThumbnailUpload(file)) {
        uploadImage(file.raw).then(({ data }) => {
          fileList.shift();
          this.mainImage = data.filename;
        });
      }
    },
    beforeThumbnailUpload(file) {
      const isImage = file.raw.type.startsWith('image/');
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isImage) {
        alert('이미지 파일이 아닙니다.');
      }
      if (!isLt5M) {
        alert('5MB 미만 파일만 업로드할 수 있습니다.');
      }
      return isImage && isLt5M;
    },
    checkByteLimit(type) {
      if (type == "long") {
        const byteCount = this.countBytes(this.longSynopsis);
        if (byteCount > this.byteLimit) {
          this.longSynopsis = this.truncateText(
            this.longSynopsis,
            this.byteLimit,
            "long"
          );
          this.byteCount = this.byteLimit;
          alert("최대 1800바이트를 초과하였습니다.");
        } else {
          this.byteCount = byteCount;
        }
      } else if (type == "short") {
        const byteCount2 = this.countBytes(this.shortSynopsis);
        if (byteCount2 > this.byteLimit2) {
          this.shortSynopsis = this.truncateText(
            this.shortSynopsis,
            this.byteLimit2,
            "short"
          );
          this.byteCount2 = this.byteLimit2;
          alert("최대 160바이트를 초과하였습니다.");
        } else {
          this.byteCount2 = byteCount2;
        }
      }
    },
    countBytes(text) {
      let count = 0;
      for (let i = 0; i < text.length; i++) {
        const charCode = text.charCodeAt(i);
        if (charCode <= 0x007f) {
          count += 1;
        } else {
          count += 2;
        }
      }
      return count;
    },
    truncateText(text, limit, type) {
      let truncatedText = "";
      if (type == "long") {
        let byteCount = 0;
        for (let i = 0; i < text.length; i++) {
          const charCode = text.charCodeAt(i);
          let charSize = 0;

          if (charCode <= 0x007f) {
            charSize = 1;
          } else {
            charSize = 2;
          }

          if (byteCount + charSize > limit) {
            break;
          }

          truncatedText += text[i];
          byteCount += charSize;
        }
        return truncatedText;
      } else if (type == "short") {
        let byteCount2 = 0;
        for (let i = 0; i < text.length; i++) {
          const charCode = text.charCodeAt(i);
          let charSize = 0;

          if (charCode <= 0x007f) {
            charSize = 1;
          } else {
            charSize = 2;
          }

          if (byteCount2 + charSize > limit) {
            break;
          }

          truncatedText += text[i];
          byteCount2 += charSize;
        }
        return truncatedText;
      }
    },
    getMetadata() {
      fetchMetadataDetail(this.metadataId).then((res) => {
        if (res.data.status == 200) {
          this.byteCount = this.countBytes(res.data.data.longSynopsis);
          this.byteCount2 = this.countBytes(res.data.data.shortSynopsis);
          this.metadataInfo = res.data.data;
          this.folderName = res.data.data.folderName;
          this.language = res.data.data.language;
          this.productionType = res.data.data.productionType;
          this.ipType = res.data.data.ipType;
          this.contentType = res.data.data.contentType;
          this.mainImage = res.data.data.mainImage;
          this.serverPath = res.data.data.serverPath;
          this.videoType = res.data.data.videoType;
          this.audioLanguage = res.data.data.audioLanguage;
          this.audioLanguages = res.data.data.audioLanguages;
          this.duration = res.data.data.duration;
          this.seriesTitleKorean = res.data.data.seriesTitleKorean;
          this.seriesTitleEnglish = res.data.data.seriesTitleEnglish;
          this.seriesSynopsisKorean = res.data.data.seriesSynopsisKorean;
          this.seriesSynopsisEnglish = res.data.data.seriesSynopsisEnglish;
          this.episodeNumber = res.data.data.episodeNumber;
          this.episodeTitleKorean = res.data.data.episodeTitleKorean;
          this.episodeTitleEnglish = res.data.data.episodeTitleEnglish;
          this.longSynopsis = res.data.data.longSynopsis;
          this.shortSynopsis = res.data.data.shortSynopsis;
          this.lyricist = res.data.data.lyricist;
          this.composer = res.data.data.composer;
          this.arranger = res.data.data.arranger;
          this.singer = res.data.data.singer;
          this.songSource = res.data.data.songSource;
          this.mainCast = res.data.data.mainCast;
          this.characters = res.data.data.characters;
          // this.mainCast = res.data.data.mainCast.split(",");
          // this.characters = res.data.data.characters.split(",");
          this.initialReleaseYear = res.data.data.initialReleaseYear;
          this.releaseDate = res.data.data.releaseDate;
          this.genre = res.data.data.genre;
          this.director = res.data.data.director;
          this.writer = res.data.data.writer;
          this.voiceActor = res.data.data.voiceActor;
          this.productionCompany = res.data.data.productionCompany;
          this.videoQuality = res.data.data.videoQuality;
          this.aspectRatio = res.data.data.aspectRatio;
          if (this.productionType == "series") {
            this.secondOptions = ["series"];
          } else if (this.productionType == "newmedia") {
            this.secondOptions = [
              "story",
              "song_dance",
              "curation",
              "shorts",
              "sound",
              "etc",
            ];
          }
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },

    handleFirstDropdownChanged() {
      this.contentType = "";
      this.secondOptions = this.dropdownItems[this.productionType] || [];
    },

    submit() {
      if (this.folderName == "") {
        return alert("폴더명을 입력해 주세요.");
        // } else if (this.language == "") {
        //   return alert("언어를 선택해 주세요.");
        // } else if (this.productionType == "") {
        //   return alert("제작유형을 선택해 주세요.");
        // } else if (this.ipType == "") {
        //   return alert("IP 유형을 선택해 주세요.");
        // } else if (this.contentType == "") {
        //   return alert("콘텐츠유형 선택해 주세요.");
        // } else if (this.mainImage == "") {
        //   return alert("대표이미지를 입력해 주세요..");
        // } else if (this.serverPath == "") {
        //   return alert("서버 경로를 입력해 주세요.");
        // } else if (this.videoType == "") {
        //   return alert("영상 구분을 선택해 주세요.");
        // } else if (this.audioLanguage == "") {
        //   return alert("오디오 언어를 선택해 주세요.");
        // } else if (this.duration == "") {
        //   return alert("상영시간을 입력해 주세요.");
        // } else if (this.seriesTitleKorean == "") {
        //   return alert("시리즈제목을 입력해 주세요.");
        // } else if (this.seriesTitleEnglish == "") {
        //   return alert("Series Title을 입력해 주세요.");
        // } else if (this.seriesSynopsisKorean == "") {
        //   return alert("시리즈 시놉시스를 입력해 주세요.");
        // } else if (this.seriesSynopsisEnglish == "") {
        //   return alert("Series Synopsis를 입력해 주세요.");
        // } else if (this.episodeNumber == "") {
        //   return alert("에피소드 회차를 입력해 주세요.");
        // } else if (this.episodeTitleKorean == "") {
        //   return alert("에피소드명(국문)을 입력해 주세요.");
        // } else if (this.episodeTitleEnglish == "") {
        //   return alert("에피소드명(dud문)을 입력해 주세요.");
        // } else if (this.longSynopsis == "") {
        //   return alert("장문 줄거리를 입력해 주세요.");
        // } else if (this.shortSynopsis == "") {
        //   return alert("단문 줄거리를 입력해 주세요.");
        // } else if (this.mainCast == "") {
        //   return alert("주연을 입력해 주세요.");
        // } else if (this.characters == "") {
        //   return alert("등장인물을 입력해 주세요.");
        // } else if (this.initialReleaseYear == "") {
        //   return alert("최초출시연도를 입력해 주세요.");
        // } else if (this.releaseDate == "") {
        //   return alert("출시날짜를 입력해 주세요.");
        // } else if (this.genre == "") {
        //   return alert("장르를 입력해 주세요.");
        // } else if (this.director == "") {
        //   return alert("감독을 입력해 주세요.");
        // } else if (this.writer == "") {
        //   return alert("작가를 입력해 주세요.");
        // } else if (this.voiceActor == "") {
        //   return alert("성우를 입력해 주세요.");
        // } else if (this.productionCompany == "") {
        //   return alert("제작사를 입력해 주세요.");
        // } else if (this.videoQuality == "") {
        //   return alert("화질을 입력해 주세요.");
        // } else if (this.aspectRatio == "") {
        //   return alert("화면비율을 입력해 주세요.");
      }
      if (this.lyricist == "") this.lyricist = "㈜아이코닉스";
      if (this.composer == "") this.composer = "㈜아이코닉스";
      if (this.arranger == "") this.arranger = "㈜아이코닉스";
      if (this.singer == "") this.singer = "㈜아이코닉스";
      if (this.songSource == "") this.songSource = "㈜아이코닉스";

      let data = {
        metadataId: this.metadataId,
        folderName: this.folderName,
        language: this.language,
        productionType: this.productionType,
        ipType: this.ipType,
        contentType: this.contentType,
        mainImage: this.mainImage,
        serverPath: this.serverPath,
        videoType: this.videoType,
        audioLanguage: this.audioLanguage,
        audioLanguages: this.audioLanguages,
        duration: this.duration,
        seriesTitleKorean: this.seriesTitleKorean,
        seriesTitleEnglish: this.seriesTitleEnglish,
        seriesSynopsisKorean: this.seriesSynopsisKorean,
        seriesSynopsisEnglish: this.seriesSynopsisEnglish,
        episodeNumber: this.episodeNumber,
        episodeTitleKorean: this.episodeTitleKorean,
        episodeTitleEnglish: this.episodeTitleEnglish,
        longSynopsis: this.longSynopsis,
        shortSynopsis: this.shortSynopsis,
        lyricist: this.lyricist,
        composer: this.composer,
        arranger: this.arranger,
        singer: this.singer,
        songSource: this.songSource,
        mainCast: this.mainCast,
        characters: this.characters,
        initialReleaseYear: this.initialReleaseYear,
        releaseDate: this.releaseDate,
        genre: this.genre,
        director: this.director,
        writer: this.writer,
        voiceActor: this.voiceActor,
        productionCompany: this.productionCompany,
        videoQuality: this.videoQuality,
        aspectRatio: this.aspectRatio,
        // confirmStatus: this.confirmStatus,
        // status: "REGISTER",
      };
      if (this.editMode) {
        let dummyWorkDetails = [];

        for (let item in this.metadataInfo) {
          if (item == "folderName") {
            if (this.metadataInfo[item] != this.folderName) {
              dummyWorkDetails.push("폴더명");
            }
          }
          if (item == "language") {
            if (this.metadataInfo[item] != this.language) {
              dummyWorkDetails.push("언어");
            }
          }
          if (item == "productionType") {
            if (this.metadataInfo[item] != this.productionType) {
              dummyWorkDetails.push("제작유형");
            }
          }
          if (item == "ipType") {
            if (this.metadataInfo[item] != this.ipType) {
              dummyWorkDetails.push("IP유형");
            }
          }
          if (item == "contentType") {
            if (this.metadataInfo[item] != this.contentType) {
              dummyWorkDetails.push("콘텐츠유형");
            }
          }
          if (item == "mainImage") {
            if (this.metadataInfo[item] != this.mainImage) {
              dummyWorkDetails.push("대표이미지");
            }
          }
          if (item == "serverPath") {
            if (this.metadataInfo[item] != this.serverPath) {
              dummyWorkDetails.push("서버경로");
            }
          }
          if (item == "videoType") {
            if (this.metadataInfo[item] != this.videoType) {
              dummyWorkDetails.push("영상구분");
            }
          }
          if (item == "audioLanguage") {
            if (this.metadataInfo[item] != this.audioLanguage) {
              dummyWorkDetails.push("오디어언어");
            }
          }
          if (item == "audioLanguages") {
            if (this.metadataInfo[item]?.join() != this.audioLanguages.join()) {
              dummyWorkDetails.push("오디오언어");
            }
          }
          if (item == "duration") {
            if (this.metadataInfo[item] != this.duration) {
              dummyWorkDetails.push("상영시간");
            }
          }
          if (item == "seriesTitleKorean") {
            if (this.metadataInfo[item] != this.seriesTitleKorean) {
              dummyWorkDetails.push("시리즈제목");
            }
          }
          if (item == "seriesTitleEnglish") {
            if (this.metadataInfo[item] != this.seriesTitleEnglish) {
              dummyWorkDetails.push("SeriesTitle");
            }
          }
          if (item == "seriesSynopsisKorean") {
            if (this.metadataInfo[item] != this.seriesSynopsisKorean) {
              dummyWorkDetails.push("시리즈시놉시스");
            }
          }
          if (item == "seriesSynopsisEnglish") {
            if (this.metadataInfo[item] != this.seriesSynopsisEnglish) {
              dummyWorkDetails.push("SeriesSynopsis");
            }
          }
          if (item == "episodeNumber") {
            if (this.metadataInfo[item] != this.episodeNumber) {
              dummyWorkDetails.push("에피소드회차");
            }
          }
          if (item == "episodeTitleKorean") {
            if (this.metadataInfo[item] != this.episodeTitleKorean) {
              dummyWorkDetails.push("에피소드명국문");
            }
          }
          if (item == "episodeTitleEnglish") {
            if (this.metadataInfo[item] != this.episodeTitleEnglish) {
              dummyWorkDetails.push("에피소드명영문");
            }
          }
          if (item == "longSynopsis") {
            if (this.metadataInfo[item] != this.longSynopsis) {
              dummyWorkDetails.push("장문줄거리");
            }
          }
          if (item == "shortSynopsis") {
            if (this.metadataInfo[item] != this.shortSynopsis) {
              dummyWorkDetails.push("단문줄거리");
            }
          }
          if (item == "lyricist") {
            if (this.metadataInfo[item] != this.lyricist) {
              dummyWorkDetails.push("작사가");
            }
          }
          if (item == "composer") {
            if (this.metadataInfo[item] != this.composer) {
              dummyWorkDetails.push("작곡자");
            }
          }
          if (item == "arranger") {
            if (this.metadataInfo[item] != this.arranger) {
              dummyWorkDetails.push("편곡자");
            }
          }
          if (item == "singer") {
            if (this.metadataInfo[item] != this.singer) {
              dummyWorkDetails.push("가수");
            }
          }
          if (item == "songSource") {
            if (this.metadataInfo[item] != this.songSource) {
              dummyWorkDetails.push("곡출처");
            }
          }
          if (item == "mainCast") {
            if (this.metadataInfo[item] != this.mainCast) {
              dummyWorkDetails.push("주연");
            }
          }
          if (item == "characters") {
            if (this.metadataInfo[item] != this.characters) {
              dummyWorkDetails.push("등장인물");
            }
          }
          if (item == "initialReleaseYear") {
            if (this.metadataInfo[item] != this.initialReleaseYear) {
              dummyWorkDetails.push("최초출시연도");
            }
          }
          if (item == "releaseDate") {
            if (this.metadataInfo[item] != this.releaseDate) {
              dummyWorkDetails.push("출시날짜");
            }
          }
          if (item == "genre") {
            if (this.metadataInfo[item] != this.genre) {
              dummyWorkDetails.push("장르");
            }
          }
          if (item == "director") {
            if (this.metadataInfo[item] != this.director) {
              dummyWorkDetails.push("감독");
            }
          }
          if (item == "writer") {
            if (this.metadataInfo[item] != this.writer) {
              dummyWorkDetails.push("작가");
            }
          }
          if (item == "voiceActor") {
            if (this.metadataInfo[item] != this.voiceActor) {
              dummyWorkDetails.push("성우");
            }
          }
          if (item == "productionCompany") {
            if (this.metadataInfo[item] != this.productionCompany) {
              dummyWorkDetails.push("제작사");
            }
          }
          if (item == "videoQuality") {
            if (this.metadataInfo[item] != this.videoQuality) {
              dummyWorkDetails.push("화질");
            }
          }
          if (item == "aspectRatio") {
            if (this.metadataInfo[item] != this.aspectRatio) {
              dummyWorkDetails.push("화면비율");
            }
          }
        }
        console.log(dummyWorkDetails);
        if (dummyWorkDetails.length == 0) {
          return;
        }
        this.workDetails = dummyWorkDetails.join(", ");
        let data = {
          metadataId: this.metadataInfo._id,
          workDetails: this.workDetails,
          folderName: this.folderName,
          language: this.language,
          productionType: this.productionType,
          ipType: this.ipType,
          contentType: this.contentType,
          mainImage: this.mainImage,
          serverPath: this.serverPath,
          videoType: this.videoType,
          audioLanguage: this.audioLanguage,
          audioLanguages: this.audioLanguages,
          duration: this.duration,
          seriesTitleKorean: this.seriesTitleKorean,
          seriesTitleEnglish: this.seriesTitleEnglish,
          seriesSynopsisKorean: this.seriesSynopsisKorean,
          seriesSynopsisEnglish: this.seriesSynopsisEnglish,
          episodeNumber: this.episodeNumber,
          episodeTitleKorean: this.episodeTitleKorean,
          episodeTitleEnglish: this.episodeTitleEnglish,
          longSynopsis: this.longSynopsis,
          shortSynopsis: this.shortSynopsis,
          lyricist: this.lyricist,
          composer: this.composer,
          arranger: this.arranger,
          singer: this.singer,
          songSource: this.songSource,
          mainCast: this.mainCast,
          characters: this.characters,
          initialReleaseYear: this.initialReleaseYear,
          releaseDate: this.releaseDate,
          genre: this.genre,
          director: this.director,
          writer: this.writer,
          voiceActor: this.voiceActor,
          productionCompany: this.productionCompany,
          videoQuality: this.videoQuality,
          aspectRatio: this.aspectRatio,
        };
        updateMetadata(data).then((res) => {
          if (res.data.status == 200) {
            alert("메타데이터 정보가 반영 되었습니다.");
            this.$router.push("/admin/datamanage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      } else {
        createMetadata(data).then((res) => {
          if (res.data.status == 200) {
            alert("메타데이터 등록이 완료 되었습니다.");
            this.$router.push("/admin/datamanage");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
    deleteData() {
      let result = confirm("메타데이터를 삭제하시겠습니까?");
      if (!result) {
        return;
      }
      deleteMetadata(this.metadataId).then((res) => {
        if (res.data.status == 200) {
          alert("메타데이터 삭제 되었습니다.");
          this.$router.push("/admin/datamanage");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
